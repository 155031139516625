.container {
  width: 100%;
  padding: 0 40px 40px 40px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container .box {
  width: 100%;
  height: 400px;
  margin: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  /* box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.5); */
  box-shadow: rgba(0, 0, 0, 0.45) -10px 25px 20px -20px;
}

.box p {
  height: 30%;
  font-size: 16px;
  /* color: #2e2e2d; */

  padding-left: 20px;
  margin: 20px;
}

.box h5 {
  height: 10%;
  color: var(--title-color);
  margin: 0;
}

.box img {
  max-height: 300px;
  max-width: 100%;
}

.box-link p {
  color: var(--link-color);
  font-size: 15px;
  padding: 0;
  margin: 5px;
}

.box .box-link {
  height: auto;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 17px;
}

.github-link {
  width: 25px !important;
  height: 25px !important;
  color: var(--link-color);

}

.link {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.box .contents-1 {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box .contents-2 {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tech {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px
}

span {
  color: var(--text-color);
  font-size: 13px;

}

.techUsed {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;
}

@media screen and (max-width:768px) {
  .container {
    flex-direction: column;
    padding: 0;
  }

  .container .box {
    width: 100%;
    height: auto;
    margin: 40px 0px;
    padding: 4px;
    display: flex;
    flex-direction: column;
  }

  .box img {
    margin: 10px;
    width: 100%;
    height: auto;
  }

  .box h4 {
    height: 40px;
    color: #b0b6bc;
    margin: 10px;
  }

  .box p {
    font-size: 16px;
    display: flex;
    align-items: center;
    padding-left: 0;


  }

  .github-link {

    width: 20px !important;
  }

  .box .contents-1,
  .box .contents-2 {
    width: 100%;

  }
  .tech {
   flex-direction: column;
  }

  .techUsed {
    width: 100%;
    justify-content: center;
  }

  .techUsed img {
    width: 20px;
    margin: 0;
  }


}