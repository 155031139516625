.footer {
  width: 100%;
  margin: 0 auto;
  padding: 3px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer p {
  font-size: 15px;
  color: var(--link-color);

}

.footer a {
  font-size: 13px;
  color: var(--link-color);

}

@media screen and (max-width:768px) {

  .footer p {
    font-size: 12px;
  }
}