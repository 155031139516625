.info-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 60px 0 30px 0;

}

.skills {
    width: 100%;
    display: flex;
  
    justify-content: center;
    margin-top: 70px;
}

.skills .info {
    width: 15%;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    border-radius: 3px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.info-container h4 {
    margin-top: 40px;
    color: var(--link-color);
}

.info h4 {
    color: var(--link-color);
    margin: 10px;
}

.info p {
    height: 60%;
    font-size: 15px;
    /* color: #2e2e2d; */
   
    margin: 9px;
}

.info-container hr {
    height: 1px;
    width: 30%;
    margin: 0;
    border: 1px solid var(--link-color);
}

.switch {
    width: "100%";
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 55px
}


.switch h6 {
    cursor: pointer;
    color: var(--link-color);

}

@media screen and (max-width:768px) {
    .info-container {
        height: auto;
        width: 100%;
       
    }

    .info-container h2 {
        font-size: 24px;
    }
    .skills{
        flex-direction: column;
    }

    .skills .info {
        width: 100%;
        height: auto;
        padding: 20px;
        margin: 0;
        
    }

    .info h4 {
        font-size: 18px;
    }

    .info p {
        font-size: 14px;

    }
    .switch h6{
        font-size: 13px;
    }
}