.contents {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 0 auto;
  text-align: center;
}

.title h4 {
  margin: 150px 0 0 0;
  color: var(--title-color);
}

.title p {
  font-size: 19px;
  margin: 5;
  color: #7c8189;

}

.title hr {
  height: 1px;
  margin-left: 30%;
  margin-right: 30%;
  border: 1px solid var(--link-color);

}


@media screen and (max-width:768px) {

  .title h4 {
    font-size: 24px;
  }

  .title p {
    font-size: 13px;
    padding: 5px;   
  }

  .title hr {
    height: 1px;

    margin: 0 10px 0 10px;
    border: 1px solid var(--link-color);

  }
}