.view{
    width: 100%;
    height: auto;
    margin:  150px 0 0  0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  
  @media screen and (max-width:768px) {
    .view a img{
      height: 50px;
    }
  }  