.cv{
    width: 100%;
    height: 100vh;
    padding-top: 80px;
      display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:768px) {
    .img{
        width: 100%;
        height: auto;
    }
}