.about {
  width: 100%;
  height: 450px;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.about .picture {
  width: auto;
  margin: 40px;
}

.picture img {
  border-radius: 100%;
}

.about .description {
  width: 40%;
  margin: 40px;
}

.about .description p {
  font-size: 19px;

  color: #7c8189;
}

.about .description h3 {
  color: var(--title-color);
}


@media screen and (max-width:768px) {
  .about {
    height: auto;
    flex-direction: column;
  }

  .about .description {
    width: 90%;

  }

  .about .picture {
    margin-top: 40px;
  }

  .about .description p {
    font-size: 17px;
  }

  .picture img {
    height: 200px;
  }

  .about .description h3 {
    margin: 0;
    text-align: center;
  }
}