.header {
  width: 100%;
  height: 100px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40%;
  top: 0;
  background-color: var(--bg-color);
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -30px;
  transition: background-color 0.3s, color 0.3s;
}

.link {
  margin: 10px;
  text-decoration: none;
  /* color: #2e2e2d; */
  color: var(--link-color);
  font-weight: 600;
  font-size: 20px;
}

/* .link:hover {
  color: #eeeeee;
} */

.nav {
  display: flex;
  align-items: center;
}

.logo {
  height: 100%;
  display: flex;
  align-items: center;

}

.logo .link-logo {
  color: var(--link-color);
}


@media screen and (max-width:768px) {

  .header {
    gap: 0;
    flex-direction: column;
  }

  .logo .img {
    width: 40px;
    height: 25px;
  }

  .nav {

    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .nav a {
    font-size: 13px;
  }

}